@import "../variables";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

#steps {
  $steps-bg: #bebebe;
  $steps-dot-size: 13px;

  blockquote {
    font-style: italic;
    color: $primary;
    font-weight: 400;
    font-size: 1.4rem;

    cite {
      font-style: normal;
    }
  }

  .col {
    position: relative;

    @include media-breakpoint-up(lg) {
      .steps-dot {
        background-color: $steps-bg;
        border-radius: $steps-dot-size;
        display: block;
        height: $steps-dot-size;
        width: $steps-dot-size;
        margin: 0 auto;
      }

      .steps-line-down {
        border-left: $steps-bg 2px dashed;
        display: block;
        height: 58px;
        margin: 0 auto 10px auto;
        width: 1px;
      }

      .steps-line-horizontal {
        border-top: $steps-bg 2px dashed;
        display: block;
        height: 1px;
        left: 50%;
        margin: 0 auto 10px auto;
        position: absolute;
        top: #{calc($steps-dot-size / 2)};
        width: 100%;
      }
    }

    .steps-description {
      background-position-x: center;
      background-position-y: 15px;
      background-repeat: no-repeat;
      border: 2px solid $steps-bg;
      display: block;
      height: 306px;
      margin: 10px auto;
      padding-top: 220px;
      width: 306px;
    }

    .steps-description.steps-brainstorm {
      background-image: url("resources/steps-brainstorm.svg");
    }

    .steps-description.steps-calculate {
      background-image: url("resources/steps-calculate.svg");
    }

    .steps-description.steps-learn {
      background-image: url("resources/steps-learn.svg");
    }
  }
}
