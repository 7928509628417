@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

#header {
  background: #090b17 url("resources/background-top.jpg") no-repeat fixed;
  background-position-x: center;
  position: relative;

  .header-organizer {
    padding-top: 21px;
    padding-bottom: 46px;
  }

  .header-lead {
    @include media-breakpoint-up(md) {
      max-width: 500px;
      font-size: 1.25rem;
    }
  }

  .header-footprint-logo {
    img {
      transform: scale(1);
      animation: pulse 2s infinite;

      @include media-breakpoint-down(md) {
        height: 142px;
      }
    }

    @include media-breakpoint-up(md) {
      max-width: 900px;
      position: absolute;
      text-align: right;
      top: 140px;
    }
  }

  .header-cta {
    margin-bottom: 60px;
    margin-top: 30px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.90);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.90);
  }
}
