@import "../variables";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

.container {
  max-width: 960px;

  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

label {
  text-align: left;
}

h1,
h2,
h3 {
  font-weight: 700;
}

h1,
h2 {
  @include media-breakpoint-up(md) {
    font-size: 45px;
  }
}

h1 > strong,
h2 > strong {
  color: $secondary;
  font-weight: inherit;
}

h1 > strong::before,
h2 > strong::before {
  content: "\A";
  white-space: pre;
}

h3,
h4 {
  color: $secondary;
}

h4 > .line {
  background: $secondary;
  height: 2px;
  width: 110px;

  @include media-breakpoint-down(md) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }

  @include media-breakpoint-up(md) {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
  }
}

.dark {
  color: #fff;

  a {
    color: #fff;
    text-decoration: none;
  }

  h4 {
    color: #fff;
  }

  h4 > .line {
    background: #e3e3e3;
  }

  .btn-primary {
    --bs-btn-bg: #{$secondary};
    --bs-btn-hover-bg: #{tint-color($secondary, $btn-hover-bg-tint-amount)};
  }
}

ul.factors > li {
  background-position-x: center;
  background-repeat: no-repeat;
  padding-top: 90px;
}

ul.factors > li.factors-appliances {
  background-image: url("resources/factors-appliances.svg");
}

ul.factors > li.factors-diet {
  background-image: url("resources/factors-diet.svg");
}

ul.factors > li.factors-electricity {
  background-image: url("resources/factors-electricity.svg");
}

ul.factors > li.factors-housing {
  background-image: url("resources/factors-housing.svg");
}

ul.factors > li.factors-transport {
  background-image: url("resources/factors-transport.svg");
}

ul.factors > li.factors-travel {
  background-image: url("resources/factors-travel.svg");
}

.download-features li {
  background: no-repeat url("resources/leaf.svg") 0 0.55rem;
  line-height: 2rem;
  padding-left: 20px;
  text-align: left;
}

#opinions {
  background-repeat: no-repeat;
  background-image: url("resources/background-light.jpg");
  background-size: cover;
  background-position: center;
}

.opinions-lead {
  blockquote {
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
  }

  @mixin quote {
    background: url("resources/quote.svg");
    content: "";
    height: 45px;
    position: absolute;
    width: 45px;
    top: -7px;
  }

  blockquote::before {
    @include quote();
    left: 5px;
  }

  blockquote::after {
    @include quote();
    transform: rotate(180deg);
    right: 5px;
  }
}

.partners {
  min-height: 150px;
}

#contact {
  background-repeat: no-repeat;
  background-image: url("resources/background-bottom.jpg");
  background-attachment: fixed;
  background-position-y: bottom;
}
