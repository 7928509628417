@import "variables";
@import "../node_modules/bootstrap/scss/bootstrap";

body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-hover-color: #fff;
}

.btn.btn-primary {
  --bs-btn-border-radius: 25px;
  --bs-btn-padding-x: 18px;
  --bs-btn-padding-y: 10px;
  --bs-btn-font-size: 20px;
}

footer {
  margin-top: auto;
}
